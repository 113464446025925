import useStore from "../../store"
import { useNavigate } from "react-router-dom"
import registerBiometric from "../../utilities/registerBiometric"
import LanguageSwitcher from "../common/LanguageSwitcher"
import { useTranslation } from 'react-i18next'
import UnitRowStyleSwitcher from "../common/UnitRowStyleSwitcher"
import ClickableRow from "../common/ClickableRow"
import InfoItem from "../common/InfoItem"
import { pushNotificationsEnabled } from "../../utilities/featureFlags"

export default function UserInfo() {
  const user = useStore((state) => state.user)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const headers = useStore((state) => state.requestHeaders)
  const setWebauthnId = useStore((state) => state.setWebauthnId)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const registerBiometricAuth = async () => {
    try {
      const biometric = await registerBiometric(headers)
      setWebauthnId(biometric.webauthnId)
      if (biometric.status === 'Successful') {
        setSuccessMessage(biometric.message)
      } else {
        setErrMessage(biometric.message)
      }
    } catch(err) {
      setErrMessage('Device auth registration cancelled')
    }
  }

  return(
    <div className="w-full lg:w-1/2 text-left p-2 bg-grey-900 rounded">
      <div className="sticky top-0 left-0 z-30 bg-grey-900 pb-2">
        <h3 className="font-header font-semibold text-md text-green">{t('User Information')}</h3>
      </div>
      <InfoItem title={t("Email")}>{user.email}</InfoItem>
      <InfoItem title={t("Phone")}>{user.text_message_number}</InfoItem>
      {user.type === 'DealerUser' &&
        <InfoItem title={t('Language')}>
          <div className="flex">
            <LanguageSwitcher />
          </div>
        </InfoItem>
      }
      <InfoItem title={t('Unit Row View')}>
        <div className="flex">
          <UnitRowStyleSwitcher />
        </div>
      </InfoItem>
      <div className="flex flex-col items-center justify-center">
        <ClickableRow text={t('Enable Device Authentication')} onClick={registerBiometricAuth} />
        <ClickableRow text={t('Reset Password')} onClick={() => navigate('/reset-password')} />
        {user.type === 'DealerUser' &&
          <>
            <ClickableRow text={t('Customer Support')} onClick={() => navigate('/customer_support')} />
            <div
              className="flex justify-end w-full text-xs text-grey-100 mt-2 mx-2 hover:cursor-pointer"
              onClick={() => navigate('/terms')}
            >
              {t('Terms of Service')}
            </div>
          </>
        }
      </div>
    </div>
  )
}