import { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider, ErrorBoundary } from '@rollbar/react'
import useStore from './store'
import requestInterceptor from './utilities/requestInterceptor'
import rollbarConfig from './configs/rollbarConfig'
import UIKitSettings from './configs/cometChatConfig'
import { CometChatUIKit } from "@cometchat/chat-uikit-react"
import router from './router'
import { MsalProvider } from '@azure/msal-react'
import { liveSupportEnabled } from './utilities/featureFlags'
import getPushNotificationToken from './utilities/pushNotificationToken'
import { pushNotificationsEnabled } from './utilities/featureFlags'
import deletePushNotificationToken from './utilities/deletePushNotificationToken'
import { initializeForegroundNotifications } from './notifications/foregroundNotifications'

export default function App({ msalInstance }) {
  const user = useStore((state) => state.user)
  const setIsOnline = useStore((state) => state.setIsOnline)
  const createActionCableConsumer = useStore((state) => state.createActionCableConsumer)
  const updateConsumer = useStore((state) => state.updateConsumer)
  const subscribeToNotifications = useStore((state) => state.subscribeToNotifications)
  const [rollbarConfiguration, setRollbarConfiguration] = useState(rollbarConfig(user))
  const createPushNotificationDevice = useStore((state) => state.createPushNotificationDevice)

  // Creates an action cable connection
  useEffect(() => {
    if (user && user.type === 'DealerUser') {
      const consumer = createActionCableConsumer()
      updateConsumer(consumer)
      subscribeToNotifications()
    }
  }, [user])

  // Resets rollbar configuration after user login to include user data
  useEffect(() => {
    setRollbarConfiguration(rollbarConfig(user))
  }, [user])

  useEffect(() => {
    if(user) {
      if (pushNotificationsEnabled) {
        getPushNotificationToken(user, createPushNotificationDevice)
        initializeForegroundNotifications(user)
      } else {
        deletePushNotificationToken(user)
      }
    }
  }, [user, pushNotificationsEnabled])

  if (liveSupportEnabled) {
    CometChatUIKit.init(UIKitSettings)
      .then(() => {
        console.log("Cometchat initialization completed successfully")
      })
      .catch(console.log)
  }

  requestInterceptor()

  useEffect(()=> {
    setIsOnline(navigator.onLine)

    window.addEventListener('online', function(e) { setIsOnline(true) })
    window.addEventListener('offline', function(e) { setIsOnline(false) })

    return () => {
      window.removeEventListener("online", function(e) { setIsOnline(true) })
      window.removeEventListener("offline", function(e) { setIsOnline(false) })
  }
  },[])

  return (
    <Provider config={rollbarConfiguration}>
      <MsalProvider instance={msalInstance}>
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </MsalProvider>
    </Provider>
  )
}