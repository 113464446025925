import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import { v2TransferEnabled } from "../utilities/featureFlags"

const initialState = {
  payment: null,
  paymentMessage: null,
  createPaymentStatus: 'idle',
  paymentType: 3
}

const createPaymentSlice = (set, get) => ({
  ...initialState,
  createPayment: (body) => {
    set((state) => ({ createPaymentStatus: 'pending' }))
      fetch(v2TransferEnabled ? `${apiUrlV2}/transfers` : `${apiUrl}/transfers/create-payment`, {

      method: 'POST',
      headers: get().requestHeaders,
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => set((state) => ({ payment: data.data, createPaymentStatus: 'complete' })))
  },
  processPayment: (paymentId) => fetch(`${v2TransferEnabled ? apiUrlV2 : apiUrl}/transfers/${paymentId}/process-transfer`, {
    method: 'PUT',
    headers: get().requestHeaders,
  }),
  cancelPayment: (paymentId) => fetch(`${v2TransferEnabled ? apiUrlV2 : apiUrl}/transfers/${paymentId}/cancel-transfer`, {
    method: 'PUT',
    headers: get().requestHeaders,
  }),
  createQuickPayoff: (body) => fetch(`${apiUrlV2}/transfers/quick-payoff`, {
    method: 'POST',
    headers: get().requestHeaders,
    body: JSON.stringify(body)
  }),
  setPaymentStatus: (status) => {set((state) => ({ createPaymentStatus: status }))},
  setPaymentMessage: (message) => {set((state) => ({ paymentMessage: message }))},
  setPaymentType: (type) => {set((state) => ({ paymentType: type }))},
  setPayment: (payment) => set((state) => ({ payment: payment })),
  resetPaymentSlice: () => {
    set(initialState)
  }
})

export default createPaymentSlice