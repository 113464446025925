import { createBrowserRouter } from "react-router-dom"

import Dashboard from './components/Dashboard'
import Login from './components/Login'
import PasswordRecovery from './components/PasswordRecovery'
import UnitPage from './components/Dealer/UnitPage/UnitPage'
import NotFound from './components/NotFound'
import AccountManagement from './components/AccountManagement/AccountManagement'
import ResetPassword from './components/ResetPassword'
import TransferHistory from './components/Dealer/TransferHistory/TransferHistory'
import Blackbook from './components/Dealer/Blackbook/Blackbook'
import ACHAuthorization from './components/AccountManagement/ACHAuthorization'
import NAP from './components/Dealer/NAP/NAP'
import Chat from './components/Dealer/Chat/Chat'
import BBSearchResult from './components/Dealer/Blackbook/BBSearchResult'
import Notifications from "./components/Notifications/Notifications"
import Root from "./components/Root"
import MSALRedirect from "./components/EmployeeLogin/MSALRedirect"
import CustomerSupport from "./components/Dealer/CustomerSupport/CustomerSupport"
import RiskPage from "./components/AccountManager/Risk/RiskPage"
import TermsOfService from "./components/Dealer/TermsOfService/TermsOfService"
import DealerDashboard from "./components/Dealer/DealerDashboard"
import SupportDashboard from "./components/Support/SupportDashboard"
import TransferPage from "./components/Dealer/TransferPage/TransferPage"
import Reminders from "./components/AccountManager/Reminders/Reminders"
import DealerEdit from "./components/AccountManager/Dealer/DealerEdit"
import BusinessDevPage from "./components/AccountManager/BusinessDevelopment/BusinessDevPage"
import ReminderPage from "./components/AccountManager/Reminders/ReminderPage"
import MobileReserveInfo from "./components/AccountManager/Reserve/MobileReserveInfo"
import TestError from "./components/TestError"
import LoginSupport from './components/LoginSupport'
import MobileAppPage from "./components/AccountManager/Apps/MobileAppPage"
import NotificationDetail from "./components/Notifications/NotificationDetail"
import MobileProspectiveDealerPage from "./components/AccountManager/Dealers/MobileProspectDealerPage"
import AuditsPage from "./components/AccountManager/Audits/AuditsPage"
import MobileNotesPage from "./components/AccountManager/Notes/MobileNotesPage"
import AmDealerDashboard from "./components/AccountManager/Dealer/AmDealerDashboard"
import AcceptInvitation from "./components/AcceptInvitation"
import CouponInfoPage from "./components/Dealer/Coupons/CouponInfoPage"
import { fieldAuditsTableEnabled, amPortalRemindersEnabled } from "./utilities/featureFlags"

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Dashboard />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'unit/:unitId',
        element: <UnitPage />
      },
      {
        path: 'apps/:appId',
        element: <MobileAppPage/>
      },
      {
        path: 'account',
        element: <AccountManagement />
      },
      {
        path: 'risk',
        element: <RiskPage/>
      },
      {
        path: 'transfers',
        element: <TransferHistory />
      },
      {
        path: 'transfers/:transfer_id',
        element: <TransferPage />
      },
      {
        path: 'blackbook',
        element: <Blackbook />
      },
      {
        path: 'blackbook/data',
        element: <BBSearchResult />
      },
      {
        path: 'ach_auth',
        element: <ACHAuthorization />
      },
      {
        path: 'nap',
        element: <NAP />
      },
      {
        path: 'nap_edit',
        element: <NAP issues={true} />
      },
      {
        path: 'chat',
        element: <Chat />
      },
      {
        path: 'notifications',
        element: <Notifications />
      },
      {
        path: 'notifications/:notification_id',
        element: <NotificationDetail />
      },
      {
        path: 'customer_support',
        element: <CustomerSupport />
      },
      {
        path: 'support_dashboard',
        element: <SupportDashboard />
      },
      {
        path: 'terms',
        element: <TermsOfService />
      },
      {
        path: 'dealers/:dealerId',
        element: <AmDealerDashboard/>
      },
      {
        path: 'dealers/:dealerId/edit',
        element: <DealerEdit />
      },
      ...(amPortalRemindersEnabled
        ? [
            {
              path: 'reminders',
              element: <Reminders />
            }
          ]
        : []
      ),
      ...(amPortalRemindersEnabled
        ? [
            {
              path: 'reminders/:reminderId',
              element: <ReminderPage />
            }
          ]
        : []
      ),
      {
        path: 'business_dev',
        element: <BusinessDevPage/>
      },
      {
        path: 'reserve_info',
        element: <MobileReserveInfo/>
      },
      {
        path: 'testerror',
        element: <TestError />
      },
      {
        path: 'prospects/:dealerId',
        element: <MobileProspectiveDealerPage/>
      },
      ...(fieldAuditsTableEnabled
        ? [
            {
              path: 'audits',
              element: <AuditsPage />
            }
          ]
        : []
      ),
      {
        path: 'notes/:type/:id',
        element: <MobileNotesPage />
      },
      {
        path: 'coupons/:id',
        element: <CouponInfoPage/>
      }
    ]
  },
  {
    path: 'accept-invitation',
    element: <AcceptInvitation />
  },
  {
    path: 'forgot-password',
    element: <PasswordRecovery />
  },
  {
    path: 'reset-password',
    element: <ResetPassword />
  },
  {
    path: 'msalauth',
    element: <MSALRedirect />
  },
  {
    path: 'loginsupport',
    element: <LoginSupport />
  },
  {
    path: '*',
    element: <NotFound />
  },
])

export default router