import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../store'
import authenticateBiometric from '../utilities/authenticateBiometric'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { LuScanFace } from "react-icons/lu"
import Spinner from './common/Spinner'
import fxLogo from '../../public/fx-header-logo-white.png'
import { apiUrl } from '../constants/apiUrl'
import { NeutralButton } from './common/Buttons'
import EmployeeLogin from './EmployeeLogin/EmployeeLogin'
import { FaRegQuestionCircle  } from "react-icons/fa"

export default function Login() {
  const navigate = useNavigate()
  const webauthnId = useStore((state) => state.webauthnId)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const resetMessages = useStore((state) => state.resetMessages)
  const setPushNotificationEnabled = useStore((state) => state.setPushNotificationEnabled)
  const loginTasks = useStore((state) => state.loginTasks)
  const [credentials, setCredentials] = useState({login: '', password: ''})
  const [submitting, setSubmitting] = useState(false)
  const [type, setType] = useState('password')
  const [iconOff, setIconOff] = useState(true)
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)

  useEffect(() => {
    authenticate()
  }, [])

  const loginTasksAndRedirect = (user) => {
    loginTasks(user)
    setPushNotificationEnabled(user.pushNotificationsEnabled)
    setDealerUnitsSearchBarValue('')
    navigate('/')
  }

  const authenticate = async () => {
    if (webauthnId) {
      try {
        const biometric = await authenticateBiometric(webauthnId)
        if (biometric.error) {
          setErrMessage(biometric.message)
        } else {
          loginTasksAndRedirect(biometric)
        }
      } catch(err) {
        setErrMessage('Biometric auth cancelled.')
      }
    }
  }

  const handleLogin = async (e) => {
    const username = credentials.login
    const password = credentials.password

    if (!username || !password) {
      setErrMessage("Please enter a username and password")
      return
    }

    try {
      setSubmitting(true)
      resetMessages()
      const response = await fetch(`${apiUrl}/auth/jwt`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
      })
      if (response.ok) {
        const data = await response.json()
        loginTasksAndRedirect(data)
      } else if (response.status == 401) {
        setErrMessage('Unable to login with the provided credentials.')
      } else {
        setErrMessage('Unable to process login request at this time.')
      }
    } catch (err) {
      setErrMessage('Unable to process login request at this time.')
    } finally {
      setSubmitting(false)
    }
  }

  const handleToggle = () => {
    if (type==='password'){
      setIconOff(false);
      setType('text')
   } else {
      setIconOff(true)
      setType('password')
   }
  }

  const loginButton = () => {
    return (
      submitting ?
      <Spinner /> :
      <NeutralButton
        text="Login"
        onClick={handleLogin}
        style="font-bold my-3 text-lg"
      />
    )
  }

  const loginOnEnterDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e)
    }
  }

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <img src={fxLogo} alt="Floorplan Xpress" className="h-20" />
      <form className="flex flex-col justify-center items-center w-72" onKeyDown={(e) => loginOnEnterDown(e)} data-testid="login_form">
        <div className="flex w-full my-2 border-solid border-2 border-grey-700 focus-within:border-grey-300 rounded">
          <input
            placeholder="Email or Username"
            value={credentials.login}
            name="email"
            title="email or username"
            className="p-3 w-full bg-black border-none focus:border-none focus:ring-0"
            onChange={(e) => setCredentials({ ...credentials, login: e.target.value })}
          />
          {webauthnId &&
            <button
              className="flex justify-around items-center"
              aria-label="biometric auth"
              type="button"
              onClick={authenticate}
              data-testid="bio-auth-button"
            >
                <LuScanFace className="mr-2 text-grey-300" size={25} alt="biometric auth" />
            </button>
          }
        </div>
        <div className="flex w-full my-2 border-solid border-2 border-grey-700 focus-within:border-grey-300 rounded">
          <input
            placeholder="Password"
            type={type}
            value={credentials.password}
            name="password"
            title="password"
            className="p-3 w-full bg-black border-none focus:border-none focus:ring-0"
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          <button className="flex justify-around items-center" aria-label="view password" type="button" onClick={handleToggle}>
            { iconOff ?
              <FiEyeOff className="mr-2 text-grey-300" size={25} alt="show password" data-testid="show-password-button" />
              : <FiEye className="mr-2 text-grey-300" size={25} alt="hide password" data-testid="hide-password-button" />
            }
          </button>
        </div>
        {loginButton()}
      </form>
      <div
        className="text-fpx-red hover:opacity-75 hover:cursor-pointer underline"
        onClick={() => navigate("/forgot-password")}
      >
        Forgot Password?
      </div>

      <EmployeeLogin />

      <div
        className="fixed top-4 right-4 p-2 text-3xl text-grey-100 rounded"
        onClick={() => navigate('loginsupport')}
      >
        <FaRegQuestionCircle />
      </div>
    </div>
  )
}