import { useState } from "react"
import useStore from "../../store"
import SelectDropdown from "./SelectDropdown"
import ReusableModal from "./ReusableModal"
import tailwind from "../../../tailwind.config"
import {
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { DateTime } from "luxon"
import DatePicker from "./Datepicker"

export default function UploadFileModal({ isOpen, onClose, dealerId, fileType, headerText }) {
  const green = tailwind.theme.colors["green"]
  const red = tailwind.theme.colors["fpx-red"]
  const grey900 = tailwind.theme.colors["grey-900"]
  const setSuccessMessage = useStore((state) => state.setSuccessMessage)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const createDealerFile = useStore((state) => state.createDealerFile)
  const fetchDealerDocuments = useStore((state) => state.fetchDealerDocuments)
  const fetchLotPhotos = useStore((state) => state.fetchLotPhotos)
  const dealerFolders = useStore((state) => state.dealerFolders)
  const [file, setFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileType, setSelectedFileType] = useState("")
  const [selectedPerson, setSelectedPerson] = useState(null)
  const people = useStore((state) => state.people)
  const todaysDate = DateTime.now().toFormat('MM/dd/yyyy')
  const [dlExpDate, setDlExpDate] = useState(null)
  const updatePerson = useStore((state) => state.updatePerson)
  const fetchPerson = useStore((state) => state.fetchPerson)
  const fetchApplication = useStore((state) => state.fetchApplication)
  const user = useStore((state) => state.user)
  const application = useStore((state) => state.application)
  const grey100 = tailwind.theme.colors["grey-100"]
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    dfile: '',
    dealer_folder_id: ''
  })

  const fileOptions = [
    { id: 'lotPhoto', name: 'Lot Photo'},
    { id: 'id', name: 'ID' },
    { id: 'document', name: 'Other File' }
  ]

  const findFolderIdByDirectoryName = (folders, directoryName) => {
    const folder = folders.find(folder => folder.attributes['directory-name'] === directoryName)
    return folder ? folder.id : null
  }

  const handleFileTypeChange = (fileType) => {
    setSelectedFileType(fileType)
    setSelectedPerson(null)
    if (fileType === "lotPhoto") {
      setFormValues({
        name: "Lot Photo",
        description: "",
        dfile: "",
        dealer_folder_id: findFolderIdByDirectoryName(dealerFolders, "Branch Files"),
      })
    } else if (fileType === "id") {
      setFormValues({
        name: "",
        description: "",
        dfile: "",
        dealer_folder_id: findFolderIdByDirectoryName(dealerFolders, "Legal Files"),
      })
    } else if (fileType === "document") {
      setFormValues({
        name: "",
        description: "",
        dfile: "",
        dealer_folder_id: findFolderIdByDirectoryName(dealerFolders, "Branch Files"),
      })
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    setFile(file)

    const reader = new FileReader()
    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('dealer_file[dfile]', file)
    formData.append('dealer_file[dealer_folder_id]', formValues.dealer_folder_id)
    if(selectedPerson) {
      formData.append("dealer_file[name]", `${selectedPerson.name}'s Driver's License (${todaysDate})`)

      await updatePerson(dealerId, selectedPerson.id, {
        dl_exp_on: dlExpDate,
        app_dl_added_on: todaysDate,
        app_dl_by: user['userInitials']
      })
      fetchApplication(application.id)
      fetchDealerDocuments(dealerId)
      setSelectedPerson(null)
    } else {
      formData.append('dealer_file[name]', formValues.name)
    }

    try {
      const response = await createDealerFile(formData, dealerId)
      if (response.ok) {
        setSuccessMessage('File uploaded successfully!')
        fetchDealerDocuments(dealerId)
        fetchLotPhotos(dealerId)
      }
    } catch (error) {
      console.error("Error uploading file:", error)
      setErrMessage("Failed to upload file.")
    }
    setFormValues({
      name: "",
      description: "",
      dfile: "",
    })
    setSelectedFileType(null)
    setFile(null)
    setImagePreview(false)
    onClose()
  }

  const handleRemoveFile = () => {
    setFile(null)
    setImagePreview(false)
  }

  return(
    <ReusableModal isOpen={isOpen} onClose={onClose} handleSubmit={handleSubmit} file={file} headerText={'Upload File'}>
      <FormControl mb={4}>
        <FormLabel>File Type</FormLabel>
        <SelectDropdown
          options={fileOptions}
          defaultText="Select file type"
          selectedItem={fileOptions.find(option => option.id === selectedFileType) || null}
          setSelectedItem={(item) => handleFileTypeChange(item.id)}
          displaySelectedItem={(item) => item.name}
          isErrored={false}
        />
      </FormControl>
      {selectedFileType === 'id' && (
        <FormControl mb={4}>
          <FormLabel>Select Person</FormLabel>
          <SelectDropdown
            options={people.map(person => ({ id: person.id, name: person.attributes['full-name'] }))}
            defaultText="Select a person"
            selectedItem={selectedPerson || null}
            setSelectedItem={(item) => setSelectedPerson(item)}
            displaySelectedItem={(item) => item.name}
            isErrored={false}
          />
          <FormLabel fontSize={'sm'} color={'white'} htmlFor="datePicker">ID Expires On</FormLabel>
          <DatePicker
            value={dlExpDate}
            onChange={(e) => setDlExpDate(e.target.value)}
            ariaLabel={"ID Expires On"}
          />
        </FormControl>
      )}
      <label className="relative bg-gray-300 rounded-md cursor-pointer">
        <span className="py-2 px-4 inline-block rounded border-2 border-grey-100 mb-4">Choose File</span>
        <input
          type="file"
          className="sr-only"
          onChange={handleFileUpload}
        />
      </label>
      {file && (
        <Button
          size="sm"
          color={grey900}
          bg={red}
          onClick={handleRemoveFile}
          className="ml-2"
        >
          X
        </Button>
      )}
      {file && (
        <div>
          <img src={imagePreview} alt="Preview" className="mt-2 w-24 h-24 object-cover border border-gray-300 rounded" />
        </div>
      )}
      {(selectedFileType !== 'lotPhoto' && selectedFileType !== 'id') && (
        <FormControl mt={4}>
          <FormLabel>File Title</FormLabel>
          <Input
            value={formValues.name || ''}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
            bg="white"
            color="black"
          />
        </FormControl>
      )}
    </ReusableModal>
  )
}