import { Input } from "@chakra-ui/react"
import tailwind from "../../../tailwind.config"
const grey100 = tailwind.theme.colors["grey-100"]

export default function DatePicker({value, onChange, maxDate, minDate, ariaLabel}) {
  return(
    <Input
      type="date"
      value={value}
      onChange={onChange}
      borderColor={grey100}
      color={grey100}
      max={maxDate}
      min={minDate}
      aria-label={ariaLabel}
    />
  )
}