import { useLocation } from 'react-router-dom'
import BackButton from "../common/BackButton"
import useStore from '../../store'
import { useNavigate } from 'react-router-dom'

export default function NavBarBackButton({ styles }) {
  const pathname = useLocation().pathname
  const breadcrumbTrail = useStore((state) => state.breadcrumbTrail)
  const user = useStore((state) => state.user)
  const setBreadcrumbTrail = useStore((state) => state.setBreadcrumbTrail)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const navigate = useNavigate()

  const handleBack = () => {
    if (user.type === 'AccountManager') {
      if (breadcrumbTrail.length > 0) {
        setBreadcrumbTrail(breadcrumbTrail.slice(0, -1))
      }
      setDashboardUnitId(null)
    }
    if (window.history.length <= 1) {
      navigate('/')
    } else {
      window.history.back()
    }
  }

  return(
    <>
      {pathname !== '/' &&
        <BackButton styles={styles} onClick={handleBack} />
      }
    </>
  )
}