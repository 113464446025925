import { DateTime } from "luxon"
import { isComingDue, isPastDue, soldUnitDueDate, dueOn } from "./unitUtilities"


export default function unitStatus(unit, matchedAttribute = null, userType=null) {
  const daysFloored = unit.attributes['days-floored']
  const accountManager = userType === 'AccountManager'

  let status = {
    style: isComingDue(unit) ? 'text-yellow' : 'text-grey-300',
    text: `Due ${dueOn(unit)}`,
    unitNameStyle: null
  }

  if (matchedAttribute == 'VIN') {
    status.style = 'text-grey-100'
    status.text = unit.attributes.vin
  } else if (matchedAttribute == 'Color') {
    status.style = 'text-grey-100'
    status.text = unit.attributes.color
  } else if (unit.attributes['nap-issues']?.length > 0) {
    status.style = 'text-yellow'
    status.text = 'Floor Request Needs Review'
  } else if (unit.attributes['ach-initiated']) {
    status.style = 'text-grey-100'
    status.text = `${unit.attributes['pending-payment-type']} Initiated ${unit.attributes['pending-transfer-info']?.split(" ").pop()}`
  } else if (isPastDue(unit)) {
    const daysPastDue = daysFloored - unit.attributes['due-in']
    status.style = 'text-fpx-red'
    status.text = `${daysPastDue} ${daysPastDue == 1 ? 'Day' : 'Days'} Past Due`
    status.unitNameStyle = 'text-grey-100'
  } else if (unit.attributes.sold) {
    status.style = 'text-yellow'
    status.text = unit.attributes['sold-on'] ?
      `Sold - Payment Due` :
      'Sold'
    status.unitNameStyle = 'text-yellow'
  } else if (unit.attributes.status === 'Pending') {
    status.style = 'text-yellow'
    status.text = 'Pending Approval'
    status.unitNameStyle = 'text-yellow'
  }

  if (accountManager) {
    if (unit.attributes['days-since-last-touch'] === "Never Touched") {
      status.style = 'text-fpx-red'
      status.text = 'Never Touched'
    } else if (unit.attributes.repo === 1 && unit.attributes['days-since-last-touch'] >= 40) {
      status.style = 'text-fpx-red'
      status.text = `REPO-- ${unit.attributes['days-since-last-touch']} days untouched`
    } else if (unit.attributes.repo === 1) {
      status.style = 'text-fpx-red'
      status.text = `REPO`
    } else if (unit.attributes['days-since-last-touch'] >= 40) {
      status.style = 'text-fpx-red'
      status.text = `${unit.attributes['days-since-last-touch']} days untouched`
    } else if (unit.attributes.sold) {
      status.style = 'text-fpx-red'
      status.text = unit.attributes['sold-on'] ?
        `Sold - Payment Due` :
        'Sold'
      status.unitNameStyle = 'text-grey-100'
    }
  }

  return status
}
