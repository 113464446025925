import { getMessaging, getToken } from "firebase/messaging"
import firebaseApp from "../configs/firebase"
import Rollbar from "rollbar"
import rollbarConfig from "../configs/rollbarConfig"


const getPushNotificationToken = async (user, createPushNotificationDevice) => {
  const messaging = getMessaging(firebaseApp)
  const apiKey = import.meta.env.VITE_PUSH_NOTIFICATIONS_API_KEY
  const rollbar = new Rollbar(rollbarConfig(user))

  return getToken(messaging, { vapidKey: apiKey })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken)
        createPushNotificationDevice({ device_token: currentToken })
      } else {
        rollbar.error("No registration token available. Request permission to generate one.")
      }
    })
    .catch((err) => {
      if (err.code === "messaging/permission-blocked") {
        rollbar.error("Notifications are blocked. Please enable them in your browser settings.")
      } else {
        rollbar.error("An error occurred while retrieving token: ", err)
      }
    })
}

export default getPushNotificationToken