import useStore from '../../store'
import { FaUser, FaHome, FaExclamationTriangle, FaBell } from 'react-icons/fa'
import { FaMoneyBillTrendUp } from "react-icons/fa6"
import { TbClockDollar } from "react-icons/tb"
import FloorCarIcon from '../common/FloorCarIcon'
import MobileNavItem from './MobileNavItem'
import { AiOutlineAudit } from 'react-icons/ai'
import { fieldAuditsTableEnabled } from '../../utilities/featureFlags'

export default function MobileNavItems() {
  const isIos = /iPhone|iPad/.test(navigator.userAgent)
  const user = useStore((state) => state.user)

  return (
    <div className={`lg:hidden flex items-start justify-evenly w-full mt-3 mb-0.5 ${isIos ? 'pb-7' : 'pb-1.5'}`}>
      <MobileNavItem
        path='/'
        icon={<FaHome size={22} />}
      />

      <MobileNavItem
        path='/account'
        icon={<FaUser size={22} />}
      />

      {user?.type === "DealerUser" &&
        <>
          <MobileNavItem
            path='/transfers'
            icon={<TbClockDollar size={22} />}
          />
          <MobileNavItem
            path='/blackbook'
            icon={<FloorCarIcon />}
          />
        </>
      }
      {user?.type === 'AccountManager' &&
        <>
          <MobileNavItem
            path={'/risk'}
            icon={<FaExclamationTriangle size={22}/>}
          />
          <MobileNavItem
            path={'/business_dev'}
            icon={<FaMoneyBillTrendUp size={22}/>}
          />
          {fieldAuditsTableEnabled &&
            <MobileNavItem
              path={'/audits'}
              icon={<AiOutlineAudit size={22}/>}
            />
          }
        </>
      }
    </div>
  )
}