import { getMessaging, onMessage } from "firebase/messaging"
import useStore from "../store"
import Rollbar from "rollbar"
import rollbarConfig from "../configs/rollbarConfig"

export const initializeForegroundNotifications = (user) => {
  const messaging = getMessaging()
  const rollbar = new Rollbar(rollbarConfig(user))

  onMessage(messaging, (payload) => {
    console.log("Received foreground message ", payload)

    const notificationTitle = payload.data.title
    const notificationOptions = {
      body: payload.data.body,
      icon: "public/fx-icon-512.png",
      data: {
        click_action: payload.data.click_action,
        unit_id: payload.data.unit_id,
        path: payload.data.path,
      }
    }

    // When you are currently looking at the app you should get an in app notification instead of a push notification
    if (window.matchMedia("(display-mode: standalone)").matches) {
      const { setSuccessMessage } = useStore.getState()
      setSuccessMessage(notificationTitle, {
        text: "View",
        url: payload.data.path
      })
    }

    if (typeof Notification !== "undefined" && Notification.permission === "granted") {
      const notification = new Notification(notificationTitle, notificationOptions)
      notification.onclick = (event) => {
        event.preventDefault()

        rollbar.info("Foreground noficiation clicked", { path: notificationOptions.data.path })

        const targetUrl = notificationOptions.data.path
          ? `${window.location.origin}/${notificationOptions.data.path}`
          : window.location.origin

        if (window.location.href !== targetUrl) {
          window.open(targetUrl, "_self")
        }
      }
    } else {
      console.log("Notification permission is not granted")
    }
  })
}