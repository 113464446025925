import { useEffect } from 'react'
import useStore from '../../store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreditUtilization from './CreditUtilization/CreditUtilization'
import CurrentUnits from './CurrentUnits/CurrentUnits'
import RegisterBiometric from '../RegisterBiometric/RegisterBiometric'
import formatter from '../../constants/currencyFormatter'
import DashboardContainer from '../common/DashboardContainer'
import UnitPageBody from '../Dealer/UnitPage/UnitPageBody'
import ReserveInfoBody from '../AccountManager/Reserve/ReserveInfoBody'
import Notes from '../AccountManager/Notes/Notes'
import Coupons from './Coupons/Coupons'
import CouponInfoPage from './Coupons/CouponInfoPage'
import { couponsEnabled } from '../../utilities/featureFlags'

export default function DealerDashboard() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useStore((state) => state.user)
  const dealer = useStore((state) => state.dealer)
  const fetchUnits = useStore((state) => state.fetchUnits)
  const fetchDealer = useStore((state) => state.fetchDealer)
  const dashboardUnitId = useStore((state) => state.dashboardUnitId)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const askRegisterBiometric = useStore((state) => state.askRegisterBiometric)
  const setActionButton = useStore((state) => state.setActionButton)
  const dealerDashboardSidePanel = useStore((state) => state.dealerDashboardSidePanel)
  const setDealerDashboardSidePanel = useStore((state) => state.setDealerDashboardSidePanel)
  const couponInfoPageId = useStore((state) => state.couponInfoPageId)
  const setCouponInfoPageId = useStore((state) => state.setCouponInfoPageId)

  useEffect(() => {
    fetchDealer(user.dealerId)
    fetchUnits()
    setDealerDashboardSidePanel(null)
  }, [])

  useEffect(() => {
    setActionButton({
      buttonText: t('Request Floor'),
      altTextHeader: t("Credit Available"),
      altTextBody: dealer ? formatter.format(dealer.attributes['total-credit-remaining']) : null,
      onClick: () => navigate('/blackbook'),
    })

    return () => setActionButton(null)
  }, [dealer])

  const onSidePanelClose = () => {
    setDealerDashboardSidePanel(null)
    setDashboardUnitId(null)
    setCouponInfoPageId(null)
  }

  const sidePanels = [
    {
      isExpanded: !dashboardUnitId && dealerDashboardSidePanel === 'reserve info',
      component: <ReserveInfoBody/>,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    },
    {
      isExpanded: dashboardUnitId,
      component: <UnitPageBody unitId={dashboardUnitId} />,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    },
    {
      isExpanded: !dashboardUnitId && dealerDashboardSidePanel === 'notes',
      component: <div className="pt-2"><Notes type='dealers' id={dealer?.id} /></div>,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    },
    {
      isExpanded: couponInfoPageId,
      component:<CouponInfoPage couponId={couponInfoPageId}/>,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    }
  ]

  return (
    <DashboardContainer sidePanels={sidePanels}>
      {askRegisterBiometric && user.type === 'DealerUser' && <RegisterBiometric />}
        <CreditUtilization />
        {couponsEnabled && <Coupons/>}
        <CurrentUnits />
    </DashboardContainer>
  )
}
